import React from 'react';

import { Wrapper, Title } from './preloader.style';
import { PreloaderIcon } from '../../assets/icons';

type PreloaderProps = {
  global?: boolean;
};

const Preloader: React.FC<PreloaderProps> = ({ global = false }) => {
  return (
    <>
      <Wrapper global={global}>
        <PreloaderIcon />
        <Title>Loading...</Title>
      </Wrapper>
    </>
  );
};

export default Preloader;
