import axios, { AxiosError, AxiosResponse } from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { setLogout } from '../store/slices/authSlice';

declare global {
  interface Window {
    Pusher: Pusher;
    Echo: Echo;
  }
}

window.Pusher = require('pusher-js');

export type ErrorType = {
  errors: any;
  message: string;
};

const token = localStorage.getItem('token');

export const urlApi = {
  prod: process.env.REACT_APP_API_URL,
};

const api = axios.create({
  baseURL: urlApi.prod,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  },
});

const options = {
  broadcaster: 'pusher',
  key: '12345',
  cluster: 'eu',
  forceTLS: false,
  wsHost: 'api.myturbodispatch.com',
  wsPort: 6001,
  enabledTransports: ['ws', 'wss'],
  authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `bearer ${token}`,
    },
  },
};

// window.Echo = new Echo(options);

api.interceptors.request.use(config => {
  let token: string | null;

  token = localStorage.getItem('token');

  // @ts-ignore
  config.headers.Authorization = `bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      setLogout();
      localStorage.removeItem('token');
      window.location.href = '/';
    } else {
      throw error.response?.data;
    }
  },
);

export default api;
