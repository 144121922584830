import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  ${(props) =>
    props.global &&
    css`
      position: fixed;
      top: 0;
      left: 0;
    `}
`;

export const Title = styled.p`
  margin-top: 10px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;
