import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  Title,
  ImportBlock,
  Actions,
  ImportText,
  Filter,
  Input,
  SearchText,
  LoadsField,
  FieldWrapper,
  FieldIcon,
  SelectedValue,
  LoadsSelect,
  Opt,
  TabPanel,
  Tab,
  Line,
  Count,
  LoadsAutocompleteSelect,
  LoadsTextField,
} from './loads.style';
import { AutocompleteOption, TabKey, TabKeys } from './type';
import { getDrivers } from '../../API/drivers';
import { getLoads } from '../../API/loads';
import { Plus, Import, SelectArrow, Search, Close } from '../../assets/icons';
import Button from '../../components/Button/Button';
import NewLoads from '../../components/Loads/LoadsTab/LoadsList';
import Container from '../../layout/Container';
import { useDebounceValue } from '../../shared/utils/hooks/debounce';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const tabKeys = ['New', 'In terminal', 'Assigned', 'Picked up', 'Delivered', 'Billed', 'Paid', 'Archived', 'Deleted'];

export const defaultLoadTabs = tabKeys.reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: 0,
  }),
  {}
);

const Loads: React.FC = () => {
  const [searchValue, setValue] = useState<string>('');
  const [searchActive, setSearchActive] = useState(false);
  const [type, setType] = useState<string>('load_id');
  const [typeName, setTypeName] = useState<string>('Load ID');
  const [driver, setDriver] = useState<string>('');
  const [driverId, setDriverId] = useState<string>('');
  const [dispatcher, setDispatcher] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('none');
  const [sortByName, setSortByName] = useState<string>('');
  const [tab, setTab] = useState<string>('New');
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const debouncedSearch = useDebounceValue(searchValue, 500);
  const debouncedSearchLength = debouncedSearch.length;

  const { loadsList, loading } = useAppSelector((state: { loads: any }) => state.loads);

  const { driversList } = useAppSelector((state: { driver: any }) => state.driver);

  const loadsStatusLength = loadsList.reduce((acc: TabKeys, cur: { status: TabKey }) => {
    if (cur.status in acc) {
      return {
        ...acc,
        [cur.status]: acc[cur.status] + 1,
      };
    }

    return acc;
  }, defaultLoadTabs);

  useEffect(() => {
    dispatch(
      getLoads({
        type: type,
        sortBy: sortBy,
        ...(debouncedSearch.length >= 3 && { search: debouncedSearch }),
        driverId,
      })
    );
  }, [type, sortBy, driverId]);

  useEffect(() => {
    setSearchActive(true);

    if (0 < debouncedSearchLength && debouncedSearchLength < 3) return;

    setSearchActive(false);

    dispatch(
      getLoads({
        type: type,
        sortBy: sortBy,
        ...(debouncedSearchLength >= 3 && { search: debouncedSearch }),
        driverId,
      })
    );
  }, [debouncedSearchLength]);

  useEffect(() => {
    dispatch(getDrivers(null));
  }, []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleClearDriver = () => {
    setDriver(() => '');
    setDriverId(() => '');
  };

  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    option: AutocompleteOption | null,
    setState: any,
    setStateIndex?: any
  ) => {
    if (option) {
      setState(option.id);
      setStateIndex(option.label);
    } else {
      setState(e.target.value);
      setStateIndex(e.currentTarget?.selectedOptions?.[0]?.label);
    }
  };

  const tabContent = (label: string, tabValue: number, countValue: number, isActive: boolean) => {
    return (
      <Tab
        active={isActive}
        key={label}
        onClick={() => {
          setCurrentPage(1);
          setTab(label);
          setTabValue(tabValue);
        }}
      >
        {label}
        {countValue > 0 && <Count active={isActive}>{countValue}</Count>}
      </Tab>
    );
  };

  const tabConfig = tabKeys.map((key, idx) => {
    const result = {
      label: key,
      activeLabel: key,
      tabIndex: idx,
      countValue: loadsStatusLength[key],
    };

    if (key === 'In terminal') {
      return {
        ...result,
        label: 'In Terminal',
        activeLabel: 'In Terminal',
      };
    }

    if (key === 'Picked up') {
      return {
        ...result,
        label: 'Picked Up',
        activeLabel: 'Picked Up',
      };
    }

    return result;
  });

  const itemListContent = (tabKey: string) => {
    const listContent = ({ filterKey, emptyListLabel }) => {
      const filteredLoads = loadsList.filter((elem: any) => elem.status === filterKey);

      return (
        <NewLoads
          loadsList={filteredLoads}
          emptyList={emptyListLabel}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      );
    };

    switch (tabKey) {
      case 'New':
      case 'Assigned':
      case 'Archived':
      case 'Billed':
      case 'Deleted':
      case 'Delivered':
      case 'Paid': {
        return listContent({ filterKey: tabKey, emptyListLabel: tabKey });
      }
      case 'In Terminal': {
        return listContent({ filterKey: 'In terminal', emptyListLabel: 'In Terminal' });
      }
      case 'Picked Up': {
        return listContent({ filterKey: 'Picked up', emptyListLabel: 'Picked Up' });
      }
      default:
        return null;
    }
  };

  if (loading) return <Preloader global={true} />;

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <Title>Loads</Title>
            <Actions>
              <ImportBlock onClick={() => navigation('/loads/download')}>
                <Import /> <ImportText>Import Load</ImportText>
              </ImportBlock>
              <div>
                <Button
                  text="Create Load"
                  pad="10px 20px"
                  icon={<Plus />}
                  handleButton={() => navigation('/loads/create')}
                />
              </div>
            </Actions>
          </Top>
          <Filter>
            <LoadsField>
              <SelectedValue>{typeName}</SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <LoadsSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, null, setType, setTypeName)}
              >
                <Opt hidden></Opt>
                <Opt value="none">None</Opt>
                <Opt value="vin">Vin</Opt>
                <Opt value="load_id">Load ID</Opt>
                <Opt value="vehicle">Vehicle</Opt>
                <Opt value="invoice_id">Invoice ID</Opt>
                <Opt value="pickup_name">Pickup name</Opt>
                <Opt value="delivery_name">Delivery name</Opt>
                <Opt value="internal_load_id">Internal load ID</Opt>
              </LoadsSelect>
            </LoadsField>
            <LoadsField>
              <Input type="text" value={searchValue} onChange={handleInput} placeholder="Search..." />
              <FieldWrapper>
                <Search />
              </FieldWrapper>
              {debouncedSearchLength < 3 && searchActive && <SearchText>Minimum 3 characters</SearchText>}
            </LoadsField>
            <LoadsField>
              <LoadsAutocompleteSelect
                clearIcon={<Close onClick={handleClearDriver} />}
                options={driversList.map((elem: any) => ({
                  label: `${elem.first_name} ${elem.last_name}`,
                  value: elem.id,
                  id: elem.id,
                }))}
                value={driver}
                renderInput={(params: any) => <LoadsTextField {...params} placeholder="Driver" size="small" />}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>, value: AutocompleteOption) =>
                  changeSelectState(e, value, setDriverId, setDriver)
                }
              />
            </LoadsField>
            <LoadsField>
              <SelectedValue style={{ fontWeight: '600', fontFamily: 'MontserratSemiBold' }}>
                {dispatcher ? dispatcher : 'Dispatchers'}
              </SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <LoadsSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, null, setDispatcher)}
              >
                {[1, 2, 3].map((item) => (
                  <Opt key={item} value={item}>
                    {item}
                  </Opt>
                ))}
              </LoadsSelect>
            </LoadsField>
            <LoadsField>
              <SelectedValue style={{ fontWeight: '600', fontFamily: 'MontserratSemiBold' }}>
                {sortByName ? sortByName : 'Sort by'}
              </SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <LoadsSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  changeSelectState(e, null, setSortBy, setSortByName)
                }
              >
                <Opt hidden></Opt>
                <Opt value="none">None</Opt>
                <Opt value="customer">Customer</Opt>
                <Opt value="pickup_city">Pickup city</Opt>
                <Opt value="delivery_city">Delivery city</Opt>
                <Opt value="pickup_state">Pickup state</Opt>
                <Opt value="delivery_state">Delivery state</Opt>
                <Opt value="picked_up_date">Picked up date</Opt>
                <Opt value="delivered_date">Delivered date</Opt>
              </LoadsSelect>
            </LoadsField>
          </Filter>

          <TabPanel variant="scrollable" value={tabValue} scrollButtons allowScrollButtonsMobile>
            {tabConfig.map(({ activeLabel, label, tabIndex, countValue }) =>
              tabContent(label, tabIndex, countValue, tab === activeLabel)
            )}
          </TabPanel>
          <Line />

          {itemListContent(tab)}
        </Wrapper>
      </Container>
    </>
  );
};

export default Loads;
