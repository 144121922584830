import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export const routes = {
  invoice: 'api/crm/invoice',
  preview: 'api/crm/preview/invoice',
  bol: 'api/crm/bol',
  previewBol: 'api/crm/preview/bol',
};

export type PreviewInvoice = string;
export type PreviewBOL = { data: string }[];

export type Invoice = {
  invoice_id: string;
  invoice_date: Date;
  sticker: boolean;
  fax?: string;
  template: string;
  emails: string[];
  loads: string[];
  attachments: string[];
};

export type BOL = {
  email: string;
  fax?: string;
  template: string;
  show_additional_info: string;
};

export const sendInvoice = createAsyncThunk<
  null,
  Invoice,
  {
    rejectValue: ErrorType;
  }
>('invoice/sendInvoice', async (sendInvoice, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.invoice}`, sendInvoice);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getPreviewInvoice = createAsyncThunk<
  PreviewInvoice,
  { sticker?: boolean; load_id: string; id?: string; date?: string },
  {
    rejectValue: ErrorType;
  }
>('invoice/getPreviewInvoice', async ({ load_id, id, date, sticker = false }, { rejectWithValue }) => {
  try {
    let reg = '&';
    if (id) {
      reg += `invoice_id=${id}&`;
    }
    if (date) {
      reg += `invoice_date=${date}&`;
    }

    const response = await api.get(`${routes.preview}/${load_id}?sticker=${sticker}${reg.slice(0, -1)}`);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const sendBOL = createAsyncThunk<
  null,
  [BOL, number],
  {
    rejectValue: ErrorType;
  }
>('invoice/sendBOL', async ([sendBOL, load_id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.bol}/${load_id}`, sendBOL);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getPreviewBOL = createAsyncThunk<
  PreviewBOL,
  number,
  {
    rejectValue: ErrorType;
  }
>('invoice/getPreviewBOL', async (load_id, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.previewBol}/${load_id}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      message: error.message,
      errors: error.errors,
    } as ErrorType);
  }
});
